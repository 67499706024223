<template>
  <div :class="isMobileOrTablet ? 'md:px-2 lg:px-9' : 'md:px-9'">
    <div
      class="flex flex-col items-start mt-14 lg:mt-20 pl-4 md:pl-2 lg:px-0 w-full pb-5 max-w-1400 mx-auto overflow-hidden"
    >
      <div class="relative">
        <div class="flex items-center gap-x-3">
          <img
            src="@/assets/svg/icons/icons-flower.svg"
            alt="Icono de flor"
            class="ml-6 w-6 h-6"
          />
          <span
            class="text-xl md:text-2xl font-semibold font-bodoni tracking-wider"
            >{{ blok.title }}</span
          >
        </div>
        <div class="w-1 h-16 bg-[#3E5391] absolute top-1 left-2 z-10"></div>
      </div>
      <div class="overflow-hidden w-full h-full">
        <div
          ref="sliderContainer"
          class="overflow-x-scroll overflow-y-hidden mt-4 pb-4 scrollbar-hidden cursor-grab active:cursor-grabbing inline-flex flex-nowrap space-x-2.5 justify-start items-start mx-auto py-0 pl-0 pr-4 translate-x-0 transition-all duration-500 w-full"
        >
          <component
            :is="item.component"
            :blok="item"
            v-for="(item, index) in blok.items"
            :key="index"
            class="rounded md:ml-1"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
defineProps({
  blok: Object,
})

const sliderContainer = ref()
const { isMobileOrTablet } = useDevice()

onMounted(() => {
  let isDown = false
  let startX
  let scrollLeft

  sliderContainer.value.addEventListener('mousedown', (e) => {
    if (!sliderContainer.value) return
    isDown = true
    sliderContainer.value.classList.add('active')
    startX = e.pageX - sliderContainer.value.offsetLeft
    scrollLeft = sliderContainer.value.scrollLeft
  })
  sliderContainer.value.addEventListener('mouseleave', () => {
    if (!sliderContainer.value) return
    isDown = false
    sliderContainer.value.classList.remove('active')
  })
  sliderContainer.value.addEventListener('mouseup', () => {
    if (!sliderContainer.value) return
    isDown = false
    sliderContainer.value.classList.remove('active')
  })
  sliderContainer.value.addEventListener('mousemove', (e) => {
    if (!sliderContainer.value) return
    if (!isDown) return
    e.preventDefault()
    const x = e.pageX - sliderContainer.value.offsetLeft
    const walk = (x - startX) * 2 //scroll-fast
    sliderContainer.value.scrollTo({
      left: scrollLeft - walk,
      behavior: 'smooth',
    })
  })
})
</script>
